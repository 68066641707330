/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import { ComponentChild } from 'preact';

// app
import { Button, Input, Link } from '@client/components';

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ title, action }: {
    title?: ComponentChild,
    action: 'signup' | 'login'
}) => {
    return (
        <div class="card floating bg white col w-3-4">

            <header class="col sp-05 l pdh-1">

                <h1 class="l mgt-1">
                    {title || <>
                        <strong class="fg primary">You're one step away</strong> from securing rare domains.
                    </>}
                </h1>

                <p class="s">
                    {action === 'login' ? 'Log in' : 'Login or Signup'} with your preferred account:
                </p>
            </header>

            <div class="grid xa2 al-fill sp-0" style={{
                gridTemplateColumns: 'repeat(auto-fit, minmax(8rem, 1fr))'
            }}>
                <Button icon="brands/google" link="/user/login/google" shape="tile" class="bg google"
                    target="_blank">
                    Google
                </Button>

                <Button icon="brands/linkedin" link="/user/login/linkedin" shape="tile" class="bg linkedin"
                    target="_blank">
                    LinkedIn
                </Button>

                <Button disabled icon="brands/twitter" link="/user/login/twitter" shape="tile" class="bg reddit"
                    target="_blank">
                    Twitter
                </Button>

                <Button icon="brands/github" link="/user/login/github" shape="tile" class="bg github"
                    target="_blank">
                    Github
                </Button>

                {/* <Button icon="brands/twitter" link="/user/login/github" shape="tile" class="bg twitter">
                    Twitter
                </Button>

                <Button icon="envelope" link="/user/login/github" shape="tile" class="bg sms">
                    SMS
                </Button> */}
            </div>

        </div>
    )
}