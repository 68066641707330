/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';

// Core
import { Router } from '@app';
import { Button, Popover, Link } from '@client/components';
import useContext from '@/client/context'; 
import useHeader from '@client/pages/useHeader';

// App
import Features from '@/client/components/Upgrade/Features';
import ContactCard from '@/client/components/ContactCard';
import * as Plans from './Plans';

// Resources
import Stan from '@/client/assets/img/testimonials/stan.jpg';
import Carmen from '@/client/assets/img/testimonials/carmen.jpeg';
import Ahmed from '@/client/assets/img/testimonials/ahmed.jpeg';
import Ellen from '@/client/assets/img/testimonials/ellen.jpeg';
import Gregory from '@/client/assets/img/testimonials/gregory.jpeg';
import StripeClimate from '@/client/assets/img/partners/stripeClimate.svg';
import Logo from '@/client/assets/identity/logo-dark.png';
import questions from './faq';
import IllustrationImg from '@/client/assets/img/illustration/pricing.jpg';

// Types
import type { TUpgradeReason } from '@/server/services/Domains';
import type { TPlans } from '@/server/services/Payment';

/*----------------------------------
- TYPES
----------------------------------*/

/*----------------------------------
- CONTROLEUR
----------------------------------*/
export default ({ askedPage, plans: plansInit }: { 
    askedPage?: string,
    plans?: TPlans
}) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const { api, route, user, data } = useContext();

    const openFeatureState = React.useState();
    const [plans, setPlans] = React.useState(plansInit);
    const [openQuestion, setOpenQuestion] = React.useState<number>(0);

    React.useEffect(() => {
        
        // If plans list not provided, load it async
        if (plansInit === undefined)
            api.get('/pricing/plans').then(setPlans)

    }, []);

    useHeader({
        title: route.path === '/pricing' ? 'Plans & Pricing' : "Upgrade Required"
    });

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    /*----------------------------------
    - RENDER COMPONENTS
    ----------------------------------*/

    const error = data.domains?.error || data.error;
    const reason = error?.id as TUpgradeReason | undefined;
    // To guests, we show the general signup message
    const shownReason = !!user && reason;

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return <>
            <div class="col" style={{ maxWidth: '70rem', width: '100%' }}>
                <div class="col al-center sp-3 card bg img ImgCard" style={{
                    backgroundImage: 'url(' + IllustrationImg + ')',
                    backgroundPosition: 'center',
                    maxWidth: '1920px',
                    width: '100%'
                }}>

                    <header class="col w-3-6 l">
                        {shownReason === 'pages' ? <>
                            <h1>
                                Access the largest database of <strong>one word domains</strong>.
                            </h1>
                            <p class="reading" style={{ color: 'white' }}>
                                Upgrade your plan now to continue exploring new pages, secure the perfect domain, and stay ahead of the competition.
                            </p>
                        </> : shownReason === 'search' ? <>
                            <h1>
                                Access additional searches. Apply advanced filters. <strong>Find better domains</strong>.
                            </h1>
                            <p class="reading" style={{ color: 'white' }}>
                                <strong>
                                    You've already used all your search credits for this month 🤷‍♂️ But you can upgrade to a plan that better suit your needs.
                                </strong>
                                Don't miss out on discovering more standout, brandable one-word domains that will drive your long-term growth.
                            </p>
                        </> : shownReason === 'filters.premium' ? <>
                            <h1>
                                {error.message || 'Access Premium Filters'}, <strong>Discover Hidden Gems Faster</strong>.
                            </h1>
                            <p class="reading" style={{ color: 'white' }}>
                                Your current plan doesn't include our advanced filters—word popularity, brandability, and sentiment analysis—that highlight the most valuable, memorable one-word domains. Upgrade to refine your search, discover hidden gems, and secure high-potential names for your brand or investment portfolio.
                            </p>
                        </> : <>
                            <h1>
                                Secure your ideal <strong>one-word domains</strong> before someone else does.
                            </h1>
                            <p class="reading" style={{ color: 'white' }}>
                                Our constantly updated database and powerful AI-driven search help you discover unique, brandable domains ready to claim today. Stand out, build trust, and drive long-term growth with a name that truly aligns with your brand.

                                {/* Secure a unique, memorable, brandable & SEO-friendly domain to elevate your brand ; stand out, build trust, and drive long-term growth. */}
                            </p>
                        </>}
                        {/* <h1>
                            Access the <strong>most complete</strong> database of the one-word domains that are <strong>still available to buy</strong>.
                        </h1>
                        <p class="reading" style={{ color: 'white' }}>
                            Get a memorable and SEO-friendly domain name that will make your business stand out and drive growth.
                        </p> */}
                    </header>
                </div>

                {plans === undefined ? (
                    <div class="col al-center h-1">
                        <i src="spin" />
                    </div>
                ) : (
                    <div class="row al-fill wrap al-center">
                    
                        <Plans.OneMonth plans={plans} featuresState={openFeatureState} reason={reason} />
    
                        <Plans.Professional plans={plans} featuresState={openFeatureState} reason={reason} />
    
                        <Plans.Enterprise plans={plans} featuresState={openFeatureState} reason={reason} />
                        
                    </div>
                )}
            </div>

            {!user && (
                <section class="pdv-2">
                    <strong>💡 Want to test first?</strong> <Link target="_blank" to="/user/signup">Get 5 free searches</Link>
                </section>
            )}

            <section class="col pdb-2">
                <ul class="grid xa4 al-fill">
                    {/* <li class="card bg grey col w-3 mgr-1">
                        <p class="row-1">
                            I built Unique Domains to help entrepreneurs finding the perfect domain name for their business.
                        </p>
                        <div class="row al-top">
                            <img src={Gaetan} class="logo" />
                            <div class="col-1 txt-left col sp-0">
                                <strong>Gaëtan Le Gac</strong>
                                <div class="txt-desc">Co-Founder @ Cross Path</div>
                            </div>
                        </div>
                    </li> */}
                    <li class="card bg grey col">
                        <p class="row-1">
                            Unique Domains made finding the perfect name for my energy startup a breeze. Thanks to their great selection and easy process, I secured petrole.io, which perfectly represents my venture. Their service was instrumental in establishing my online presence.
                        </p>
                        <div class="row al-top
                        ">
                            <img src={Stan} class="logo" />
                            <div class="col-1 txt-left col sp-0">
                                <strong>Stanislas Durand</strong>
                                <div class="txt-desc">Founder @ Petrole.io</div>
                            </div>
                        </div>
                    </li>
                    <li class="card bg grey col">
                        <p class="row-1">
                            I'm in the domaining industry since 2 years, and Unique Domains has been a game-changer. I purchased several undervalued domains at low prices and recently resold one for 16 times its original value. Their platform is a treasure trove for domain investors!
                        </p>
                        <div class="row al-top
                        ">
                            <img src={Carmen} class="logo" />
                            <div class="col-1 txt-left col sp-0">
                                <strong>Carmen B.</strong>
                                <div class="txt-desc">Domain Investor</div>
                            </div>
                        </div>
                    </li>

                    <li class="card bg grey col">
                        <p class="row-1">
                            I'm always on the hunt for hidden gems. Unique.Domains' powerful filters helped me land benchpress.ai, a timeless name that's sure to appreciate in value. The platform's insights gave me the confidence to invest without hesitation.
                        </p>
                        <div class="row al-top
                        ">
                            <img src={Ahmed} class="logo" />
                            <div class="col-1 txt-left col sp-0">
                                <strong>Ahmed A.</strong>
                                <div class="txt-desc">Serial Domain Investor & Flipper</div>
                            </div>
                        </div>
                    </li>
                    {/* <li class="card bg grey col">
                        <p class="row-1">
                            I'm in the domaining industry since 2 years, and Unique Domains has been a game-changer. I purchased several undervalued domains at low prices and recently resold one for 16 times its original value. Their platform is a treasure trove for domain investors!
                        </p>
                        <div class="row al-top
                        ">
                            <img src={Gregory} class="logo" />
                            <div class="col-1 txt-left col sp-0">
                                <strong>Gregory S.</strong>
                                <div class="txt-desc">Domain Investor</div>
                            </div>
                        </div>
                    </li> */}
                    {/* <li class="card bg grey col">
                        <p class="row-1">
                            I'm in the domaining industry since 2 years, and Unique Domains has been a game-changer. I purchased several undervalued domains at low prices and recently resold one for 16 times its original value. Their platform is a treasure trove for domain investors!
                        </p>
                        <div class="row al-top
                        ">
                            <img src={Ellen} class="logo" />
                            <div class="col-1 txt-left col sp-0">
                                <strong>Ellen L.</strong>
                                <div class="txt-desc">Domain Investor</div>
                            </div>
                        </div>
                    </li> */}
                </ul>
            </section>

            <section class="pdv-4 col al-center bt-1" style={{
                boxShadow: '0 -10px 10px rgba(0, 0, 0, 0.03)'
            }}>
                <h2>Frequently Asked Questions</h2>

                <ul class="col w-3-8 txt-left">
                    {questions.map((question, qIndex) => (
                        <li class="card col inside sp-0">
                            <div class="row pdv-1 clickable" onClick={() => setOpenQuestion(qIndex)}>
                                <i src={question.icon} class="fg primary" />
                                <strong class="col-1">{question.question}</strong>
                                <i src={qIndex === openQuestion ? "angle-up" : "angle-down"} />
                            </div>
                            {qIndex === openQuestion && (
                                <p class="reading pdt-0">
                                    {question.answer}
                                </p>
                            )}
                        </li>
                    ))}
                </ul>
            </section>

            <ContactCard subject="Pricing, plans and features" />

            <div class="col al-center pdv-3">
                <div class="row">
                    <img src={StripeClimate} style={{ width: '3em' }} />

                    <div class="col-1 col al-left sp-0">
                        <strong>Unique Domains is a proud partner of Stripe Climate.</strong>
                        We contribute 1% of our revenue to carbon removal.
                        <Link to="https://climate.stripe.com/fLGYbz">Learn more</Link>
                    </div>
                </div>
            </div>
    </>
}