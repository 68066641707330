/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';

// Core
import { Router } from '@app';
import { Input, Link, Button } from '@client/components';

// app
import DomainCard from '@/client/components/DomainCard';

// Types
import type { TDomainsList } from '@/server/services/Domains';
import type { domains as Domain } from '@/server/models';

// App
import Listing from './domains/Listing';
import Articles from '@/client/components/Articles';
import Page from './_page';
import SearchBar from './domains/SearchBar';
import LandingHeroImg from '@/client/assets/img/illustration/home-hero.jpeg';

/*----------------------------------
- CONTROLEUR
----------------------------------*/
Router.page('/', ({ api, app, request, data }) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const { domains, sponsored, articles } = api.fetch({
        //stats: api.get<TDomainsStats>('/stats/general'),
        domains: api.post<TDomainsList>('/domains'),
        sponsored: api.get<Domain[]>('/domains/sponsored'),
        articles: api.get('/blog'),
    });

    // Lazy load shhoting stars
    /*const [ShootingStarsBackground, setShootingStarsBackground] = React.useState(null);
    if (typeof document !== 'undefined') {
        import('@/client/components/ShootingStars').then((module) => {
            setShootingStarsBackground(module);
        });
    }*/
    const [MarqueeComponent, setMarquee] = React.useState(null);
    React.useEffect(() => {
        import("react-fast-marquee").then((module) => {
            setMarquee(module);
        });
    });

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    const Marquee = MarqueeComponent ? MarqueeComponent.default : 'div';
    return (
        <Page
            title="Unique Domains - Find the highest potential domains for your business"
            description="Access all the most valuable and brandable domain names that are still available to buy, and find a highly memorable and SEO-friendly name for your business."
            //header={( <DomainFilters /> )}
        >

            {/* <MeshBackground /> */}

            {/*ShootingStarsBackground && <ShootingStarsBackground.default />*/}

            {/* <img src={AISphere} style={{ height: 160, borderRadius: 160 }} /> */}

            
            <SearchBar />

            <section class="col mgh-cover">
                <Marquee gradient pauseOnHover>
                    <div class="Domains row sp-0 al-left pdv-1">
                        {sponsored.map(domain => (
                            <DomainCard domain={domain} 
                               className="mgr-1" />
                        ))}
                    </div>
                </Marquee>
            </section> 

            <section class="col pdv-1">
                <Listing domains={domains} level={2} />
            </section>

            <section class="col pdv-1">
                <header class="row sp-btw">
                    <h2 class="txt-left">University of Domaining</h2>
                    <Button iconR="long-arrow-right" link="/blog">
                        Learn about Domaining
                    </Button>
                </header>

                <Articles articles={articles} />
            </section>

            {/* <section class="col sp-0">

                <h2 class="txt-left">Latest Articles</h2>

                <div class="Sponsored Domains row scrollable pdv-1 pageCover">
                    {sponsored.map(domain => (
                        <DomainCard domain={domain}  />
                    ))}
                </div>
            </section> */}

            {/* <div class="domains grid">
                {domains.list.map(domain => (
                    <DomainCard domain={domain} searchId={domains.searchId} />
                ))}
            </div> */}

            {/* <section class="col sp-05">
                <header class="row sp-btw">
                    <h2>Examples of Companies using a One Word Domain</h2>
                </header>
                <Marquee className="domainExamples" pauseOnHover>
                    <ul class="row al-left sp-0">
                        {domainExamples.map(({ domain, color, logo, description }) => (
                            <li class="w-3 col al-center mgr-1">
                                <Link class="super" to={'https://' + domain} title={description}>

                                    <img loading="lazy" src={logo} />

                                    <h3>{domain}</h3>

                                    <div>
                                        <p>{description}</p>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Marquee>
            </section>

            <Listing domains={domains} stats={stats} /> */}

            {/* <section class="col pdv-3 sp-3">

                <header>
                    <h2>Why you should own one word domains?</h2>
                </header>

                <ul class="grid al-fill" style={{
                    gridTemplateColumns: 'repeat(auto-fit, minmax(18rem, 1fr))'
                }}>
                    <li class="card col al-center">
                        <i src="dollar-sign" class="l solid bg light1" />
                        <h3>Rare and Valuable</h3>
                        <p>One-word domains are limited, making them as precious as gold.</p>
                    </li>

                    <li class="card col al-center">
                        <i src="lightbulb" class="l solid bg light2" />
                        <h3>Easy to Remember</h3>
                        <p>Simple and catchy, they stick in people's minds.</p>
                    </li>

                    <li class="card col al-center">
                        <i src="check-circle" class="l solid bg light3" />
                        <h3>Boosts Credibility</h3>
                        <p>Instantly makes your brand look established and trustworthy.</p>
                    </li>

                    <li class="card col al-center">
                        <i src="search" class="l solid bg light4" />
                        <h3>SEO Friendly</h3>
                        <p>Often rank higher in search results, driving more traffic.</p>
                    </li>

                    <li class="card col al-center">
                        <i src="chart-line" class="l solid bg light5" />
                        <h3>Great Investment</h3>
                        <p>They appreciate over time, often reselling for a premium.</p>
                    </li>

                    <li class="card col al-center">
                        <i src="globe" class="l solid bg light6" />
                        <h3>Universal Appeal</h3>
                        <p>Recognizable across languages and cultures.</p>
                    </li>

                    <li class="card col al-center">
                        <i src="user-tie" class="l solid bg light7" />
                        <h3>Professional and Simple</h3>
                        <p>Clean and straightforward, they build trust.</p>
                    </li>

                    <li class="card col al-center">
                        <i src="puzzle-piece" class="l solid bg light1" />
                        <h3>Versatile</h3>
                        <p>Perfect for any industry or purpose.</p>
                    </li>

                    <li class="card col al-center">
                        <i src="star" class="l solid bg light2" />
                        <h3>Competitive Edge</h3>
                        <p>Stand out from the competition with a unique domain.</p>
                    </li>

                </ul>

            </section>

            <section class="card bg img row h-6" style={{
                background: 'url(' + BgImg1 + ') center/cover',
            }}>
                <div class="card bg img col sp-2 w-3-4 pd-2">

                    <h2 class="txt-left">Give a try to the AI Domains Finder!</h2>

                    <p>
                        I spent 1 month and thousands of dollars to build the best one-word domain finder on the market (and yes, it's real AI, not bullshit).
                    </p>

                    <Button iconR='arrow-up' onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                        setTimeout(() => {
                            document.querySelector('#search input')?.focus();
                        }, 1000);
                    }}>
                        Find the perfect Domain
                    </Button>
                    
                </div>
            </section> */}

            {/* <section class="card bg img pd-3 row">

                <div class="w-3-4 col al-centr sp-2" al-center>

                    <i src="envelope" class="xl solid bg info" />

                    <h2>Top grade one word domains, every week, in your mailbox.</h2>

                    <Button type="primary" icon="bell">Subscribe</Button>
                </div>

            </section>  */}
        </Page>
    )
});