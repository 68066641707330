/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React, { JSX } from 'react';

// Core
import useContext from '@/client/context'; 

// Core components
import { Button } from '@client/components';

// App compponents
import FavoriteBtn from './Domain/FavoriteBtn';

/*----------------------------------
- TYPES
----------------------------------*/

import type { domains as Domain } from '@/server/models';

// With basic div attributes
type TProps = JSX.HTMLAttributes<HTMLDivElement> & {
    domain: Domain
    searchId?: string,
};

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ domain, searchId, ...props }: TProps) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const { api } = useContext();

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return (
        <article class={"card col pd-1 " + 
            (props.className || '')
        }>

            <a href={domain.url} target="_blank" rel="nofollow">
                Buy Domain
            </a>

            <ul class="row sp-btw menu row-1 al-top">
    
                <li>
                    <FavoriteBtn domain={domain} />
                </li>

                <li class="row">
                    {/*domain.usageFrequency && <>
                        {domain.usageFrequency}
    </>*/}
                </li>
    
            </ul>

            {/*domain.id === null ? (
                <img src={domain.logoUrl} alt="Domain Name Logo" class="domainName pdh-1" />
            ) : */(
                <div class="contLogo">
                    <img loading="lazy" title="View domain name" src={domain.logoUrlV2} alt="Domain name logo"  />
                </div>
            )}
    
            <ul class="row sp-btw row-1 al-bottom">
    
                <li class="row sp-05">
                    <i src="mouse-pointer" />
                    {domain.viewsCount}
                </li>

                {domain.purchaseType === 'sponsor' ? (
                    <li class="Sponsored fg gold">
                        <strong>Sponsored</strong>
                    </li>
                ) : !!domain.purchasePrice && <>
                    <li class="price">
                        {domain.purchasePrice}
                    </li>
                </>}

            </ul>

            {/* <ul class="details col txt-left">
                <li class="col al-fill sp-05">
                    Positivity
                    <div class="progressbar">
                        <div class="progress" style={{ width: '70%' }} />
                    </div>
                </li>
                <li class="col al-fill sp-05">
                    Popularity
                    <div class="progressbar">
                        <div class="progress" style={{ width: '70%' }} />
                    </div>
                </li>
                <li class="col al-fill sp-05">
                    Brandability
                    <div class="progressbar">
                        <div class="progress" style={{ width: '70%' }} />
                    </div>
                </li>
            </ul> */}
    
        </article>
    )
}