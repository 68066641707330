export default [
    {
      question: "What is Unique.Domains, and how does it benefit me?",
      answer: "Unique.Domains is an AI-powered platform that helps you discover, filter, and secure memorable one-word domain names. Whether you’re a startup founder, marketer, or domain investor, you’ll benefit from our up-to-date database of brandable domains and powerful filtering options.",
      icon: /* @icon */"info-circle"
    },
    {
      question: "How frequently is the domain database updated?",
      answer: "We refresh our database everyday, ensuring you always have access to the latest data on available one-word domains. This frequent updating helps you act quickly before someone else registers a sought-after name.",
      icon: /* @icon */"sync"
    },
    {
      question: "What makes a one-word domain more valuable or brandable than multi-word domains?",
      answer: "One-word domains are often more memorable, simpler to type, and carry a sense of authority. They stand out in search results and can create a strong, lasting impression—qualities that are crucial for marketing and brand recognition.",
      icon: /* @icon */"magic"
    },
    {
      question: "Can I use the AI-powered search to find domains in any industry or niche?",
      answer: "Absolutely. Our AI is trained on a diverse dataset of English words, enabling you to find relevant domain options for virtually any sector—from tech startups to creative agencies and beyond.",
      icon: /* @icon */"search"
    },
    {
      question: "What happens if a domain I see on Unique.Domains is already taken by the time I attempt to purchase it?",
      answer: "Domain availability can change rapidly, and registration works on a first-come, first-served basis. If a domain is taken before you can register it, we’ll show you alternative suggestions and help you refine your search.",
      icon: /* @icon */"exclamation-circle"
    },
    {
      question: "Are there any usage limits for the AI-powered search tool under each plan?",
      answer: "Yes. Each plan offers a set number of 'search credits' or queries, and higher-tier plans have more generous limits. You can find these details in your account dashboard or on our pricing page.",
      icon: /* @icon */"balance-scale"
    },
    {
      question: "How do the ‘Basic Filters’ and ‘Premium Filters’ differ?",
      answer: "Basic Filters let you refine domain choices by essential attributes like length or domain extension. Premium Filters include advanced options such as brandability, popularity, sentiment, or other AI-enhanced indicators that help you pinpoint high-potential domains more precisely.",
      icon: /* @icon */"filter"
    },
    {
      question: "Do you offer any refunds or guarantees if I can’t find a domain that suits my needs?",
      answer: "We don’t provide refunds simply because domain availability can be unpredictable. However, our plans offer a range of search and filter capabilities to maximize your chances of finding the perfect name.",
      icon: /* @icon */"undo"
    },
    {
      question: "What are search credits, and how do they work?",
      answer: "Search credits represent how many times you can query our database with specific keywords or filters. Each search request deducts one credit, and the total you have depends on your subscription plan. Once you reach your limit, you can either upgrade your plan or purchase additional credits (when available).",
      icon: /* @icon */"coins"
    },
    {
      question: "Can I buy additional search credits if I run out before my plan renews?",
      answer: "Yes. Depending on your subscription plan, you may purchase extra credits if you need more searches before your monthly cycle resets or your pass expires.",
      icon: /* @icon */"plus-circle"
    },
    {
      question: "What kind of support do I get as a subscriber?",
      answer: "All subscribers receive email and chat support, with a guaranteed response within 24 hours. Higher-tier plans may include priority support, ensuring faster response times and dedicated assistance.",
      icon: /* @icon */"life-ring"
    },
    {
      question: "Is there an option to upgrade or downgrade my plan at any time?",
      answer: "Yes. You can change your plan at any point. Upgrades usually take effect immediately, while downgrades become active at the end of your current billing cycle.",
      icon: /* @icon */"arrow-up"
    },
    {
      question: "How do I actually purchase the domain once I find one I like on Unique.Domains?",
      answer: "We provide direct links or registrar recommendations so you can register the domain through a trusted provider. After you choose your desired name, simply follow the steps on the registrar’s website to complete your purchase.",
      icon: /* @icon */"shopping-cart"
    },
    {
      question: "Are there hidden fees or extra costs not included in the subscription price?",
      answer: "We do not charge additional fees for using our platform beyond your subscription. However, you’ll need to pay your chosen registrar’s cost for any domain you purchase.",
      icon: /* @icon */"money-check"
    },
    {
      question: "What are the differences between the monthly Professional plan and the Enterprise plan besides search limits?",
      answer: "The Enterprise plan often includes additional features like unlimited pages per search, more domain exports, extra tools for domain analysis, competitive tracking, and premium support—making it ideal for high-volume users or agencies.",
      icon: /* @icon */"layer-group"
    },
    {
      question: "Who should opt for the ‘30 days pass’ versus the recurring subscriptions?",
      answer: "If you’re a startup founder or individual looking for a one-time search to secure a single domain, the 30-day pass is perfect. For ongoing needs—like domain investing, continuous brand projects, or agency-level demand—a recurring subscription provides better value and extended access.",
      icon: /* @icon */"question-circle"
    },
    {
      question: "Can I export my search results or domain list from your platform?",
      answer: "Yes. Depending on your plan, you can export a certain number of domain search results (e.g., up to 100 or unlimited) in CSV format for easy reference, sharing, or offline analysis.",
      icon: /* @icon */"download"
    },
    {
      question: "What tools does Unique.Domains provide to estimate a domain’s resale potential?",
      answer: "Our advanced AI assigns brandability and popularity scores, as well as sentiment insights. Combined with historical sales data and trending keywords, these tools help you gauge the potential return on investment for each domain.",
      icon: /* @icon */"chart-line"
    },
    {
      question: "Is there any training or onboarding material on how to make the most of the filters?",
      answer: "Yes. We offer step-by-step tutorials, documentation, and tips within the platform. Professional and Enterprise users also receive priority support to help navigate advanced features.",
      icon: /* @icon */"chalkboard-teacher"
    },
    {
      question: "Does Unique.Domains integrate with popular domain registrars for seamless purchasing?",
      answer: "We currently provide direct links to top registrars. Full integration with select registrars is on our roadmap, so you can look forward to a more seamless buying experience soon.",
      icon: /* @icon */"plug"
    },
    {
      question: "How secure is my account information and payment data on your platform?",
      answer: "We use industry-standard encryption (SSL) for all transactions and secure payment processors for billing. Your private data is protected, and we regularly review our security measures to ensure a safe experience.",
      icon: /* @icon */"lock"
    }
  ];
  