/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';

// Core
import { Router } from '@app';
import { Button, Popover, Link } from '@client/components';
import useContext from '@/client/context';

// App
import type { TSubjectsList } from '@/common/forms/contact';
import type { domains as Domain } from '@/server/models';

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ domain }: { 
    domain: Domain
}) => {
    const { api } = useContext();

    const hasChanged = React.useRef(false);

    const [isFavorite, setIsFavorite] = React.useState(domain.isFavorite);

    React.useEffect(() => {
        
        if (hasChanged.current)
            api.post('/user/favorite', { domainId: domain.id, isFavorite }).run().catch(() => {
                hasChanged.current = false;
                setIsFavorite(!isFavorite);
            });

        hasChanged.current = true;

    }, [isFavorite]);

    return (
        <Button icon={isFavorite ? /* @icon */"solid/heart" : /* @icon */"regular/heart"} 
            shape='pill'
            size="s" onClick={() => setIsFavorite(!isFavorite)} 
            hint="Save this domain name in my favorites" />
    )
    
}