/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React, { JSX } from 'react';

// Core
import useContext from '@/client/context'; 

// Core components
import { Button } from '@client/components';
import Table, { Props as TTableProps } from '@client/components/Table';

// App compponents
import FavoriteBtn from './Domain/FavoriteBtn';

/*----------------------------------
- TYPES
----------------------------------*/

import type { TDomainsList } from '@/server/services/Domains';
import type { domains as Domain } from '@/server/models';

// With basic div attributes
type TProps = TTableProps<Domain> & {
    domains: TDomainsList
};

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ domains, ...props }: TProps) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const { api } = useContext();

    // let clickUrl = '/domain/' + domain.id + '/go';
    // if (searchId)
    //     clickUrl += '?searchId=' + searchId;

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    console.log("domains.sorted", domains.sorted);
    

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return (
        <Table {...props} data={domains.list} sort={domains.sorted} columns={(domain) => [{
            label: 'Domain',
            cell: domain.name,
            stick: true,
            width: '200px'
        },{
            label: 'Length',
            cell: domain.size || '-'
        },{
            label: 'Positivity',
            cell: domain.sentiment || '-',
            sort: { id: 'wordSentiment', order: 'desc' },
            title: 'Sort by positivity score'
        },{
            label: 'Brandability',
            cell: domain.brandability || '-'
        },{
            label: 'Market',
            cell: domain.market
        },{
            label: 'Price',
            cell: domain.purchaseType === 'sponsor' ? (
                <div class="Sponsored fg gold">
                    <strong>Sponsored</strong>
                </div>
            ) : !!domain.purchasePrice && <>
                <div class="price">
                    {domain.purchasePrice}
                </div>
            </>
        },{
            label: 'Views',
            cell: domain.viewsCount
        },{
            label: 'Demand',
            cell: domain.demand || '-',
            sort: { id: 'wordDemand', order: 'desc' },
            title: 'Sort by demand score'
        },{
            label: 'Actions',
            cell: (
                <div class="row sp-05 al-right">
                    <FavoriteBtn domain={domain} />

                    <Button icon="shopping-cart" link={domain.url} hint="Buy Domain" shape='pill' />
                </div>
            )
        }]} />
    )
}