/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';

// Core
import { Router } from '@app';
import { Button, Popover, Link } from '@client/components';
import useContext from '@/client/context';

// App
import type { TSubjectsList } from '@/common/forms/contact';

// Resources
import Anna from '@/client/assets/img/testimonials/anna.jpeg';

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ subject, children }: { 
    subject: TSubjectsList, 
    children?: ComponentChild 
}) => (
    <section class="w-3-6 col">

        {children || <>
            <h2>Have a question?</h2>

            <p class="reading">
                Our team is here to assist you 7/7.
            </p>
        </>}

        <div class="row fill al-top">

            <div class="card row al-top al-left">

                <img src={Anna} class="logo xxl" style={{ borderRadius: "50%" }} />
                <div class="col al-left sp-0">

                    <h3 class="xl">Anna</h3>

                    <div class="reading">
                        Co-Founder & CMO
                    </div>
                    
                    <div class="row al-left sp-05 mgt-05">

                        <Button size='l' icon="brands/whatsapp" type="secondary" shape="pill"
                            title="Contact me on WhatsApp"
                            link="https://wa.me/33756947512">
                            Whatsapp
                        </Button>

                        <Button size='l' icon="envelope" type="secondary" shape="pill"
                            title="Contact me via Email"
                            link={"/help/contact" + (subject 
                                ? '?subject=' + encodeURIComponent(subject)
                                : '')} />

                        <Button size='l' icon="brands/linkedin" type="secondary" shape="pill"
                            title="Follow me on LinkedIn"
                            link="https://www.linkedin.com/in/anna-klein-123201348" />

                        <Button size='l' icon="brands/instagram" type="secondary" shape="pill"
                            title="Follow me on Instagram"
                            link="https://wa.me/33756947512" />
                    </div>
                </div>    
            </div>
        </div>
    </section>
)