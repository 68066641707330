/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';

// Core
import { Input, Link, Button } from '@client/components';
import useContext from '@/client/context';

/*----------------------------------
- TYPES
----------------------------------*/

export type TProps = {
    search?: string
}

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ search }: TProps) => {

    const { app, data } = useContext();

    const [keywords, setKeywords] = React.useState();

    const SearchBar = (
        <div class="col txt-left col-1 w-3-8">

            {search === undefined ? <>

                <div class="col al-left">
                    <h1 class="w-3-7">
                        Find the Domain Name that will <strong><span>Drive</span> <span>your</span> Growth</strong>.
                    </h1>
                </div>

                {/* <p class="reading">
                    Unique.Domains is the largest and most updated database of one word domain names of the world.
                </p> */}

                <ul class="row al-left al-fill scrollable">
                    <li class="col al-left card pdh-1" style={{ minWidth: '220px', flex: '0 0 220px' }}>
                        <i src="coins" class="fg primary" />
                        <p class="txt-left">
                            <strong>Acquire a strong asset</strong> that boosts business value and can be resold at a higher price.
                        </p>
                    </li>
                    <li class="col al-left card pdh-1" style={{ minWidth: '220px', flex: '0 0 220px' }}>
                        <i src="brain" class="fg primary" />
                        <p class="txt-left">
                            <strong>Stay in people's mind</strong> through a memorable name that exudes trust.
                        </p>
                    </li>
                    <li class="col al-left card pdh-1" style={{ minWidth: '220px', flex: '0 0 220px' }}>
                        <i src="magnet" class="fg primary" />
                        <p class="txt-left">
                            <strong>Dominate search engines</strong>, turn them into a long-term user acquisition source.
                        </p>
                    </li>
                </ul>
            
            </> : <>

                <h2>
                    <strong>Search Available</strong> {search}
                </h2>
            
            </>}

            <Input icon="search" wrapper={false} maxLength={150} autoFocus={search === undefined}
                value={keywords} onChange={setKeywords} onPressEnter={() => app.search(keywords)}
                title="Describe your business" suffix={(
                    <Button onClick={() => app.search(keywords)} 
                        type="primary" shape="pill" size="s" hint="Search Domains" 
                    >
                        Search
                    </Button>
                )} /> 

            <ul class="row menu al-left scrollable mgh-cover pdh-page">
                {data.navigation.trends.map(s => (
                    <li>
                        <Button link={s.url}>
                            {s.name}
                        </Button>
                    </li>
                ))}
            </ul>

        </div>
    )

    return search === undefined ? (
        <header class="Hero row al-center pdt-1 pdb-3" style={{ minHeight: '60vh' }}>
            {SearchBar}
        </header> 
    ) : (
        <footer class="Hero row al-center pdv-5">
            {SearchBar}
        </footer> 
    )
}