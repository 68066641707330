/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React, { JSX } from 'react';

// Core
import useContext from '@/client/context'; 
import { ago } from '@common/data/dates';

// Core components
import { Link, Button } from '@client/components';

/*----------------------------------
- TYPES
----------------------------------*/

import type { Article, domains as Domain } from '@/server/models';

// With basic div attributes
type TProps = JSX.HTMLAttributes<HTMLDivElement> & {
    articles: Article[]
};

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ articles, ...props }: TProps) => {

    return (
        <div class="grid xa3 al-fill" style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(23rem, 1fr))',
        }}>
            {articles.map( article => article.slug === 'dummy' ? (
                <div class="card bg silver" />
            ) : (
                <article class="col sp-05" style={{ position: 'relative' }}>

                    <img src={article.thumbnail} alt={article.thumbnailAltText || article.title} style={{ 
                        backgroundColor: 'black',
                        borderRadius: '1.25rem' ,
                        flex: '0 0 15em',
                        height: '15em',
                        objectFit: 'cover'
                    }} />

                    <h3 class="txt-left">
                        <Link className="super" to={article.url}>
                            {article.title}
                        </Link>
                    </h3>

                    <ul class="row sp-05 wrap txt-desc">

                        {article.topics?.map(tag => (
                            <li class="badge">
                                {tag}
                            </li>
                        ))}
                    </ul>
                    
                </article>
            ))}
        </div>
    )
}