/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';
import dayjs from 'dayjs';

// Core
import { Router } from '@app';
import { Button, Input, Link } from '@client/components';
import useContext from '@/client/context';

// App components
import DomainCard from '@/client/components/DomainCard';
import DomainsTable from '@/client/components/DomainsTable';
import DomainFilters from '@/client/pages/platform/domains/Listing/Filters';
import SearchBar from '../SearchBar';
// Conversion
import AuthCard from '../../../convert/login/AuthCard';
import Upgrade from '../../../convert/upgrade/Pricing';

// App
import { getCurrentFilters, buildSearchUrl } from '@/common/libs/filters';
import type { TDomainsList } from '@/server/services/Domains';

// Resources
import IllustrationImg from '@/client/assets/img/illustration/search.jpg';
import SphereWebm from '@/client/assets/motion/sphere.webm';

const loadingText = [
    'Finding the perfect name…',
    'Just a moment, creativity at work…',
    'Naming magic in progress…',
    'Hold tight, crafting unique ideas…',
    'Almost there, your ideal domain is coming…',
    'Creativity takes time, please wait…',
    'Curating the best options for you…',
    'Hang on, unique names on the way…',
    'Naming AI at work, please hold on…',
    'Almost ready, finding your perfect match…',
]

/*----------------------------------
- TYPES
----------------------------------*/

const Blur = ({ children }: { children: ComponentChild }) => (

    <div id="searching">
        <div class="col al-center" style={{
            minHeight: '50vh'
        }}>

            {children}

        </div>
    </div>
)

const Waitress = ({ domains }: { domains: TDomainsList }) => {

    const { api, request } = useContext();

    React.useEffect(() => {
        api.get('/domains/search/' + domains.searchId + '/wait').then((results) => {

            if (results)
                api.set({ domains: results });
            else
                window.location.reload();

        })
    }, []);

    const [text, setText] = React.useState(loadingText[0]);
    const [index, setIndex] = React.useState(0);
    const rotateText = () => {
        setIndex(index + 1);
        setText(loadingText[index % loadingText.length]);
    }

    React.useEffect(() => {

        const timer = setInterval(() => {
            rotateText();
        }, 1500);

        return () => clearInterval(timer);
    });

    return <>

        <video
            autoplay
            muted
            loop
            playsinline
            preload="auto"
            style={{ height: 160, borderRadius: 160 }}
        >
            <source src={SphereWebm} type="video/webm" />
        </video>

        <div class="card floating pd-1" style={{ maxWidth: 200 }}>
            {text}
        </div>
    </>
}

/*----------------------------------
- CONTROLEUR
----------------------------------*/
export default ({ domains, level = 1 }: { 
    domains: TDomainsList,
    level?: number
}) => {

    const { api, app, context, response, toast, page } = useContext();

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const currentFilters = getCurrentFilters(context);

    const lastPage = Math.min( Math.floor(domains.count / 24), 20 );
    let pagesDisplayRange = 4;
    const pageListMin = Math.max(2, currentFilters.page - pagesDisplayRange);
    
    const pageListMax = Math.min(lastPage - 1, currentFilters.page + pagesDisplayRange);
    if (pageListMax - pageListMin < pagesDisplayRange)
        pagesDisplayRange = pageListMax - pageListMin;

    const pagesList = [1]
    for (let i = pageListMin; i <= pageListMax; i++) {
        pagesList.push(i)
    }

    const showPagination = currentFilters.keywords !== undefined;

    const TitleTag = 'h' + level;

    if (domains.error && response)
        response.statusCode = domains.error.code;

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    const saveSearch = e => api.post('/user/search', { filters: JSON.stringify(currentFilters) }).then(() => {
        toast.success('Search saved. You will receive notifications when new domains match your criteria.');
    });

    const onSort = (column: string | null, order: string) => 
        app.Router.go( buildSearchUrl({ sort: column || undefined }, context, true) );

    /*----------------------------------
    - RENDER
    ----------------------------------*/

    return <>

        {domains.status !== 'ready' && (
            <Blur>
                {domains.status === 'searching' ? (
                    <Waitress domains={domains} />
                ) : domains.status === 'signup' ? (
                    <AuthCard action="signup" title={<>
                        Experience the power of Unique Domains with <strong>5 Free Preview Searches</strong>.
                    </>} />
                ) : (
                    <Upgrade />
                )}
            </Blur>
        )}

        <header class="col pdb-2 sp-0">

            <DomainFilters />

            <div class="pdt-1 row al-fill sp-3 sp-btw wrap">

                <div class="col w-3-6">

                    <TitleTag style={{ zIndex: 5 }}>
                        {domains.title}
                    </TitleTag>

                    <p class="reading">
                        {domains.description}
                    </p>

                    <div class="row wrap al-left">

                        <Button icon="paper-plane"
                            onClick={() => toast.success("Feature coming soon")} 
                            hint="Share this search to a colleague or a friend.">

                            Share

                        </Button>

                        <Button icon="bookmark"
                            async onClick={saveSearch} 
                            hint="Save this search for later">

                            Bookmark

                        </Button>

                        <Button icon="download"
                            onClick={() => toast.success("Feature coming soon")} 
                            hint="Export results as CSV">

                            Download Results

                        </Button>

                    </div>
                </div>

                <div class="col sp-btw w-3 txt-left">

                    <ul class="card col sp-05">
                        <li class="col al-left sp-0">
                            <strong class="l">{domains.count.toLocaleString()}</strong>
                            Domains available
                        </li>
                        {/* <li class="col al-left sp-0">
                            <strong class="l">-</strong>
                            Already sold
                        </li> */}
                    </ul>

                    <div class="col sp-05">
                        <strong>Example of successful domains</strong>
                        <ul class="row wrap sp-05">
                            {domains.exampleWebsites?.map( domain => (
                                <li>
                                    <Button type="secondary" size="xs" link={'https://' + domain} rel="nofollow">
                                        {domain}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div class="w-3-9 Desktop" style={{
                    position: 'relative'
                }}>
                    <img loading="lazy" class="img col" 
                        src={IllustrationImg/*'/domains/search/' + domains.searchId + '/image'*/} style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }} />
                </div>
            </div>
        </header>

        <section id="Domains" class={"col"}>
            {domains.count === 0 ? (
                <div class="card">
                    We would find any quality domain matching your filters. Please try again with different filters.
                </div>
            ) : <>

                {currentFilters.view === 'table' ? (
                    <DomainsTable domains={domains} onSort={onSort} />
                ) : (
                    <div class="Domains grid">
                        {domains.list.map(domain => (
                            <DomainCard domain={domain} searchId={domains.searchId} />
                        ))}
                    </div>
                )}
                
                {showPagination && (
                    <footer class="row pdb-3">
                        <ul class="row menu">
                            {pagesList.map((pageNum) => (
                                <li>
                                    <Button link={buildSearchUrl({ page: pageNum }, context)} 
                                        active={currentFilters.page === pageNum}
                                        shape="pill"
                                        rel="nofollow">
                                        {pageNum}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </footer>
                )}
            </>}
        </section>

        {!showPagination && (
            <SearchBar search={domains.title} />
        )}
    </>
}