/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';
import { useErrorBoundary } from 'preact/hooks';

// Core
import RouterComponent from '@client/services/router/components/router';
import { Button, Popover, Link, Input } from '@client/components';
import getPosition from '@client/components/containers/Popover/getPosition';
import { Props as TBtnProps } from '@client/components/button'
;import { deepContains } from '@client/utils/dom';
import useContext from '@/client/context';

// App
import { buildSearchUrl, getCurrentFilters} from '@/common/libs/filters';
import DomainFiltersForm from '@/common/forms/domains/filters';

/*----------------------------------
- COMPONENT
----------------------------------*/

export default () => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const { api, app, context, page, toast, data } = useContext();

    const currentFilters = getCurrentFilters(context);

    if (currentFilters.keywords === undefined)
        return null;

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    /*----------------------------------
    - RENDER
    ----------------------------------*/

    const FilterChangeButton = ({ filter, value, ...btnProps }: {
        filter: string,
        value: string,
    } & TBtnProps) => {
        return (
            <li>
                <Button
                    active={currentFilters[filter] === value}
                    link={buildSearchUrl({ [filter]: value, page: 1 }, context, true)}
                    children={btnProps.title}
                    {...btnProps}
                    target={undefined}
                    class="col-1"
                />
            </li>
        )
    }

    // TODO:; improve access
    const brandabilityChoices = DomainFiltersForm.fields.brandability[1][0];
    const popularityChoices = DomainFiltersForm.fields.popularity[1][0];
    const emotionChoices = DomainFiltersForm.fields.emotion[1][0];
    const demandChoices = DomainFiltersForm.fields.demand[1][0];
    const marketChoices = DomainFiltersForm.fields.market[1][0];
    const typeChoices = DomainFiltersForm.fields.type[1][0];
    const sortChoices = DomainFiltersForm.fields.sort[1][0];

    const missionFilters = [{
        label: 'Price',
        fields: ['minPrice', 'maxPrice'],
        choices: <>
            <li>
                <Button onClick={() => app.Router.go(buildSearchUrl({
                    maxPrice: 30
                }, context))}>Under 30$</Button>
            </li>
            <li>
                <Button onClick={() => app.Router.go(buildSearchUrl({
                    minPrice: 30, maxPrice: 100
                }, context))}>30-100$</Button>
            </li>
            <li>
                <Button onClick={() => app.Router.go(buildSearchUrl({
                    minPrice: 100, maxPrice: 500
                }, context))}>100-500$</Button>
            </li>
            <li>
                <Button onClick={() => app.Router.go(buildSearchUrl({
                    minPrice: 500
                }, context))}>500+</Button>
            </li>
        </>
    }, {
        label: 'Length',
        fields: ['minLength', 'maxLength'],
        choices: <>
            <li>
                <Button onClick={() => app.Router.go(buildSearchUrl({
                    minLength: 1, maxLength: 5
                }, context))}>Short (1-5 characters)</Button>
            </li>
            <li>
                <Button onClick={() => app.Router.go(buildSearchUrl({
                    minLength: 6, maxLength: 9
                }, context))}>Medium (6-9 characters)</Button>
            </li>
            <li>
                <Button onClick={() => app.Router.go(buildSearchUrl({
                    minLength: 10, maxLength: 15
                }, context))}>Long (10-15 characters)</Button>
            </li>
        </>
    }, {
        label: 'Extensions',
        fields: ['tld'],
        choices: data.navigation.tlds.map(tld => (
            <FilterChangeButton filter="tld" value={tld} title={'.' + tld} />
        ))
    }, {
        label: 'Sentiment',
        fields: ['emotion'],
        choices: emotionChoices.map(choice => (
            <FilterChangeButton filter="emotion" value={choice.value} title={choice.label} />
        ))
    }, {
        label: 'Popularity',
        fields: ['popularity'],
        choices: popularityChoices.map(choice => (
            <FilterChangeButton filter="popularity" value={choice.value} title={choice.label} />
        ))
    }, {
        label: 'Brandability',
        fields: ['brandability'],
        choices: brandabilityChoices.map(choice => (
            <FilterChangeButton filter="brandability" value={choice.value} title={choice.label} />
        ))
    }, {
        label: 'Demand',
        fields: ['demand'],
        choices: demandChoices.map(choice => (
            <FilterChangeButton filter="demand" value={choice.value} title={choice.label} />
        ))
    }, {
        label: 'Market',
        fields: ['market'],
        choices: marketChoices.map(choice => (
            <FilterChangeButton filter="market" value={choice.value} title={choice.label} />
        ))
    }, {
        label: 'Word Type',
        fields: ['type'],
        choices: typeChoices.map(choice => (
            <FilterChangeButton filter="type" value={choice.value} title={choice.label} />
        ))
    }, {
        label: 'Sort by',
        fields: ['sort'],
        choices: sortChoices.map(choice => (
            <FilterChangeButton filter="sort" value={choice.value} title={choice.label} />
        ))
    }]

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return (
        <nav class="col mgb-05 mgh-cover">
            <ul class="row menu al-left scrollable pdh-page">

                <Popover tag="li" content={(
                    <ul class="bg white col menu sp-05">
                        <li>
                            <Button icon="th-large" active={currentFilters.view === 'grid'}
                                link={buildSearchUrl({ view: 'grid' }, context, true)}>
                                Grid view
                            </Button>
                        </li>
                        <li>
                            <Button icon="table" active={currentFilters.view === 'table'}
                                link={buildSearchUrl({ view: 'table' }, context, true)}>
                                Table view
                            </Button>
                        </li>
                    </ul>
                )}>
                    <Button iconR="angle-down" icon="th-large">
                        {currentFilters.view === 'table' ? 'Table' : 'Grid'} view
                    </Button>
                </Popover>

                {missionFilters.map(({ label, fields, choices }, filterIndex) => {

                    const hasFilter = fields.some(field => currentFilters[field]);

                    const emptyFilters = { page: 1 };
                    for (const field of fields)
                        emptyFilters[ field ] = undefined;

                    return (
                        <Popover tag="li" content={(
                            <ul class="bg white col menu sp-05">
                                {hasFilter && (
                                    <li>
                                        <Button icon="times"
                                            link={buildSearchUrl(emptyFilters, context, true)}>
                                            Clear Filter
                                        </Button>
                                    </li>
                                )}
                                {choices}
                            </ul>
                        )}>
                            <Button iconR="angle-down" icon={hasFilter && (
                                <span class={"pastille bg color" + (filterIndex + 1)} />
                            )}>
                                {hasFilter ? (
                                    <strong>{label}</strong>
                                ) : label}
                            </Button>
                        </Popover>
                    )
                })}
            </ul>
        </nav>
    )

}