/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';

// Core
import useContext, { ClientContext } from '@/client/context';
import useHeader from '@client/pages/useHeader';
import { Input, Popover, Button, Link } from '@client/components';

/*----------------------------------
- TYPES
----------------------------------*/

export type Props = { 
    header?: ComponentChild | ComponentChild[],
    children?: ComponentChild | ComponentChild[],
    title: string,
    description: string,
    class?: string,
}

/*----------------------------------
- DEPENDANCES
----------------------------------*/
export default ({ 
    header: pageHeader, children,
    title, description, 
    class: className 
}: Props) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const { data: { navigation }, user, api, context, app, request, toast } = useContext();

    useHeader({ title, description });

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

   

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return (
        <div id="contPage" class="col col-1">

            {pageHeader && (
                <header>
                    {pageHeader}
                </header>
            )}

            <main id="page" class={className || "col col-1"}>
                {children}
            </main>

            {/* <aside id="rightBar" class="col pdv-1">
                <div class="col">

                    <div class="row-1 col scrollable">
                        {sidebar}

                        <strong>From the University</strong>
                        <ul class="col sp-0">
                            {navigation.university.map((article) => (
                                <li class="row sp-05 al-top pdv-1 bb-1" style={{ position: 'relative' }}>

                                    <div alt={article.thumbnailAltText} style={{
                                        backgroundColor: 'black',
                                        borderRadius: '0.75rem',
                                        flex: '0 0 4em',
                                        height: '4em',
                                        objectFit: 'cover'
                                    }} />

                                    <h3 class="txt-left m">
                                        <Link className="super" to={article.url}>
                                            {article.title}
                                        </Link>
                                    </h3>
                                </li>
                            ))}
                        </ul>

                        <strong>Latest Domain News</strong>
                        <ul class="col sp-0">
                            {navigation.news.map((article) => (
                                <li class="row sp-05 al-top pdv-1 bb-1" style={{ position: 'relative' }}>

                                    <div alt={article.thumbnailAltText} style={{
                                        backgroundColor: 'black',
                                        borderRadius: '0.75rem',
                                        flex: '0 0 4em',
                                        height: '4em',
                                        objectFit: 'cover'
                                    }} />

                                    <h3 class="txt-left m">
                                        <Link className="super" to={article.url}>
                                            {article.title}
                                        </Link>
                                    </h3>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div class="card bg dark h-3">

                    </div>
                </div>
            </aside> */}
        </div>
    )
}